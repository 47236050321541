import type { CamelCasedPropertiesDeep } from 'type-fest';

import { getRestClient } from '../commonSettings';
import type { Role, SimpleRole } from '../types/backend';
import type {
  CaseStudyId,
  CohortId,
  CounselorProfileId,
  CourseId,
  DateTime,
  EditableFormId,
  FeedbackRequestId,
  HubSpotTransactionalEmailId,
  KeywordId,
  MentorProfileId,
  MentorRequestId,
  MessageTicketId,
  ProductId,
  ProjectId,
  ProspectiveUserId,
  RoleId,
  RoomId,
  SilentRoomUserReportId,
  Slug,
  StudentApplicationId,
  StudentProfileId,
  StudentScholarPageId,
  SymposiumId,
  UUID,
  UserId,
  WorkspaceId,
} from '../types/common';
import type {
  EmailSearchParams,
  EmptyResponse,
  Paginated,
  ProjectSearchParams,
  UserSearchParams,
} from '../types/data/common';
import type {
  ApplicationRejectionReasons,
  PingTypes,
  StudentApplicationAccept,
} from '../types/data/zeus';
import type { FeedbackReviewer } from '../types/hermes';
import type { ExternalStudentProfile } from '../types/marketplace';
import type { PodsCourseCreate } from '../types/pods';
import type { EditableForm } from '../types/survey';
import type {
  StringUserType,
  UserInfoForUserCreate,
  UserWithProfileIdAndPicture,
} from '../types/user';
import type { Nullable } from '../types/utils';
import type {
  ActiveProjectsCounts,
  AdminPaymentIntentProducts,
  AdminProject,
  AdminWelcomePage,
  AdmissionAdvisorStudentApplication,
  Configuration,
  ConnectableStudent,
  EmailModel,
  FeatureRequest,
  HandleFeedbackRequestPayload,
  HubSpotTransactionalEmail,
  Interest,
  Keyword,
  MessageTicket,
  PartnerChannel,
  PartnershipArrangement,
  PaymentIntent,
  PaymentIntentAdminForm,
  PodsCohortCreatePayload,
  PodsCohortDetails,
  PodsCourseDetails,
  PremiumShowcasingSupportMatching,
  PremiumShowcasingSupportMatchingUpdate,
  ProjectOverviewList,
  ProjectRequestsGroupCreate,
  ProjectRequestsGroupCreatePayload,
  ResearchHub,
  ResearchHubList,
  ResearchHubUpdate,
  Symposium,
  SymposiumPresentersUpdate,
  TableauReport,
  TaskMonitoring,
  UserPermissionUpdate,
  WorkspaceAdmin,
  WorkspaceAdminDetails,
} from '../types/zeus';

export const getConnectableStudents = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<ConnectableStudent[]>('/zeus/connectable-students/');
};

export const updateStudent = <T>(studentId: StudentProfileId, payload: T) => {
  const internalFetch = getRestClient();
  return internalFetch.patch(`/zeus/students/${studentId}/`, payload);
};

export const createInterest = (payload: Omit<Partial<Interest>, 'id'>) => {
  const internalFetch = getRestClient();
  return internalFetch.post<Interest>('/zeus/interests/', payload);
};

export const createProjectRequestsGroup = (payload: ProjectRequestsGroupCreatePayload) => {
  const internalFetch = getRestClient();
  return internalFetch.post<ProjectRequestsGroupCreate>('/zeus/project-requests-groups/', payload);
};

export const sendPMentorInterviewInvite = (id: ProspectiveUserId) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>(`/zeus/prospective-mentor/${id}/interview-invite/`);
};

export const putProspectiveMentorOnWaitlist = (id: ProspectiveUserId) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>(`/zeus/prospective-mentor/${id}/waitlist/`);
};

export const activeProjectCounts = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<ActiveProjectsCounts>(`/zeus/active-projects/counts/`);
};

export const getKeywords = (query = '') => {
  const internalFetch = getRestClient();
  return internalFetch.get<Keyword[]>(`/zeus/keywords/?q=${query}`);
};

export const createKeyword = (payload: Partial<Keyword & { mentorRequestId: MentorRequestId }>) => {
  const internalFetch = getRestClient();
  return internalFetch.post<Keyword>('/zeus/keywords/', payload);
};

export const addKeywordsToMentorRequest = (
  id: MentorRequestId,
  payload: { keywords: KeywordId[] },
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<{ keywords: Keyword[] }>(
    `/zeus/mentor_requests/${id}/keywords/`,
    payload,
  );
};

export const searchWorkspaces = (search: string, pageSize = 20) => {
  const internalFetch = getRestClient();
  return internalFetch.get<WorkspaceAdmin[]>(
    `/zeus/workspaces/?search=${search}&page_size=${pageSize}`,
  );
};

export const getWorkspaceDetails = (workspaceId: WorkspaceId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<WorkspaceAdminDetails>(`/zeus/workspaces/${workspaceId}/`);
};

export const removeWorkspaceParticipant = (data: { userId: UserId; workspaceId: WorkspaceId }) => {
  const internalFetch = getRestClient();
  return internalFetch.post<WorkspaceAdminDetails>(`/zeus/workspaces/remove_participant/`, data);
};

export const addWorkspaceParticipant = (data: { userId: UserId; workspaceId: WorkspaceId }) => {
  const internalFetch = getRestClient();
  return internalFetch.post<WorkspaceAdminDetails>(`/zeus/workspaces/add_participant/`, data);
};

export const searchUsers = (searchObj: UserSearchParams) => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ count: number; results: UserWithProfileIdAndPicture[] }>(
    `/zeus/users/`,
    {
      params: searchObj,
    },
  );
};

export const searchStudentProjects = (search: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<AdminProject[]>('/zeus/student-projects/', { params: { search } });
};

export const getTableauReports = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<TableauReport[]>('/zeus/tableau-reports/');
};

export const triggerProposition = ({ mentorRequestId }: { mentorRequestId: MentorRequestId }) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>(
    `/zeus/mentor_requests/${String(mentorRequestId)}/proposition/`,
  );
};

export const getLatestSymposium = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<Symposium>(`/zeus/latest-symposium/`);
};

export const updateSymposium = (
  id: SymposiumId,
  payload: { participants: StudentScholarPageId[] },
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<SymposiumPresentersUpdate[]>(`/zeus/symposiums/${id}/`, payload);
};

export const getStudentApplication = (studentId: StudentProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<AdmissionAdvisorStudentApplication>(
    `/zeus/student-applications/${studentId}/`,
  );
};

export const acceptStudentApplication = (
  studentId: StudentProfileId,
  applicationId: StudentApplicationId,
  payload: StudentApplicationAccept,
) => {
  const internalFetch = getRestClient();
  return internalFetch.post<
    CamelCasedPropertiesDeep<
      { success: boolean; accepted_at: DateTime } | { success: boolean; message: string }
    >
  >(`/zeus/students/${studentId}/applications/${applicationId}/accept/`, payload);
};

export const updateStudentApplication = (
  studentId: StudentProfileId,
  payload: Record<string, unknown>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<AdmissionAdvisorStudentApplication>(
    `/zeus/student-applications/${studentId}/`,
    payload,
  );
};

export const getCounselorStudents = (profileId: CounselorProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<ExternalStudentProfile[]>(`/zeus/counselors/${profileId}/students/`);
};

export const getPartnershipArrangements = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<PartnershipArrangement[]>('/zeus/arrangements/');
};

export const getUserForUserTypeChange = (email: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<
    CamelCasedPropertiesDeep<{
      user: UserWithProfileIdAndPicture;
      can_change: boolean;
    }>
  >('/zeus/users/change-type/', { params: { email } });
};

export const postChangeUserType = (userId: UserId, userType: StringUserType) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean }>(`/zeus/users/change-type/${userId}/`, {
    userType,
  });
};

export const postCreateUser = (data: UserInfoForUserCreate) => {
  const internalFetch = getRestClient();
  return internalFetch.post<
    CamelCasedPropertiesDeep<{ success: boolean; verification_expire_at: DateTime; email: string }>
  >('/zeus/users/create-user/', {
    ...data,
  });
};

export const getPartnerChannels = (search = '') => {
  const internalFetch = getRestClient();
  return internalFetch.get<PartnerChannel[]>('/zeus/partner_channels/', { params: { search } });
};

export const declineStudent = (
  student_id: StudentProfileId,
  application_id: StudentApplicationId,
  payload: { note: string },
) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean; message: string }>(
    `/zeus/students/${student_id}/applications/${application_id}/decline/`,
    payload,
  );
};

export const pendingStudentDeclineActions = (
  pk: StudentProfileId,
  payload: {
    action: 'confirm' | 'undo';
    send_email?: boolean;
    reason?: ApplicationRejectionReasons;
  },
) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean; message: string }>(
    `/zeus/pending_student_decline_actions/${pk}/`,
    payload,
  );
};

export const studentPipelineCounts = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<
    CamelCasedPropertiesDeep<{
      under_review: number;
      student_decline_pending: number;
      pending_payment: number;
      matching: number;
      rematching: number;
      matched: number;
      scholarship_waitlist: number;
      registered_students: number;
    }>
  >(`/zeus/student-pipeline/counts/`);
};

export const mentorsCount = (columns: string[]) => {
  const internalFetch = getRestClient();
  const params = new URLSearchParams('');
  columns.forEach((column) => {
    params.append('columns', column);
  });
  return internalFetch.get<
    CamelCasedPropertiesDeep<{
      proposable: number;
      non_proposable: number;
    }>
  >(`/zeus/mentors_count/?${params.toString()}`);
};

export const getForm = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<EditableForm[]>('/zeus/forms/');
};

export const updateForm = (
  id: EditableFormId,
  formData: {
    name: string;
    title: string;
    description: string;
  },
  schema: Record<string, unknown>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<EditableForm>(`/zeus/forms/${id}/`, { ...formData, fields: schema });
};

export const sendMentorBonusPayment = (
  formData: { [k: string]: FormDataEntryValue },
  mentorId: MentorProfileId,
) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>('/zeus/mentor-bonus-payment/', {
    ...formData,
    mentorId,
  });
};

export const readMessageTicket = (id: MessageTicketId) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>(`/zeus/message-tickets/read/${id}/`);
};

export const seenMessageTickets = (ticketArray: MessageTicketId[]) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>('/zeus/message-tickets/seen/', ticketArray);
};

export const sendMessageTicket = (id: MessageTicketId, newMessage: string) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>(`/zeus/message-tickets/${id}/messages/`, { newMessage });
};

export const updateMessageTicket = (id: MessageTicketId) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<MessageTicket>(`/zeus/message-tickets/${id}/`, {
    completedAt: new Date(),
  });
};

export const searchMessageTickets = (searchParams: URLSearchParams) => {
  const internalFetch = getRestClient();
  return internalFetch.get<MessageTicket[]>('/zeus/message-tickets/', { params: searchParams });
};

export const retrieveChatEmails = (emails: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<EmailModel[]>('/zeus/chat-emails/', {
    params: { to_email__in: emails },
  });
};

export const retrieveChatEmailsPaginated = (searchObj: EmailSearchParams) => {
  const internalFetch = getRestClient();
  return internalFetch.get<Paginated<EmailModel>>('/zeus/chat-emails/paginated/', {
    params: searchObj,
  });
};

export const retrieveHubspoEmails = (emails: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<HubSpotTransactionalEmail[]>('/zeus/hubspot-emails/', {
    params: { to__in: emails },
  });
};

export const retrieveHubspotEmailsPaginated = (searchObj: EmailSearchParams) => {
  const internalFetch = getRestClient();
  return internalFetch.get<Paginated<HubSpotTransactionalEmail>>(
    '/zeus/hubspot-emails/paginated/',
    {
      params: searchObj,
    },
  );
};

export const resendHubspotTransactionalEmail = (id: HubSpotTransactionalEmailId) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean }>(`zeus/resend-transactional-email/${id}/`);
};

export const getWelcomePage = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<AdminWelcomePage>('/zeus/welcome-page/');
};

export const pingProject = (payload: {
  roomId: RoomId;
  userId: UserId;
  reportId?: SilentRoomUserReportId;
  pingType: PingTypes;
}) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>('/zeus/projects/ping/', payload);
};

export const snoozeProject = (id: ProjectId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ success: boolean }>(`/zeus/projects/${id}/snooze/`);
};

export const updateProjectAdmin = (id: ProjectId, payload: Record<string, unknown>) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<AdminProject>(`/zeus/project/${id}/`, payload);
};

export const getPaymentIntent = (uuid: UUID) => {
  const internalFetch = getRestClient();
  return internalFetch.get<PaymentIntent>(`/zeus/payment-intent/${uuid}/`);
};

export const updatePaymentIntent = (
  uuid: UUID,
  payload: Pick<
    PaymentIntent,
    | 'baseAmount'
    | 'amount'
    | 'riskPremium'
    | 'description'
    | 'extra'
    | 'installmentCount'
    | 'featureTypeChangeByUser'
  >,
) => {
  const internalFetch = getRestClient();
  return internalFetch.put<PaymentIntent>(`/zeus/payment-intent/${uuid}/`, payload);
};

export const previewPaymentIntentPrice = (uuid: UUID, selectedProducts: ProductId[] = []) => {
  const queryParams = new URLSearchParams(
    selectedProducts.map((id) => ['selectedProducts', String(id)]),
  );

  const internalFetch = getRestClient();
  return internalFetch.get<{ price: number; description: string }>(
    `/zeus/payment-intent/${uuid}/preview_price/?${queryParams.toString()}`,
  );
};

export const paymentIntentManualRefund = (
  uuid: string,
  formData: Pick<PaymentIntentAdminForm, 'amount' | 'reasonForChange'>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<PaymentIntent>(
    `/zeus/payment-intent/${uuid}/manual_refund/`,
    formData,
  );
};

export const paymentIntentManualAddPayment = (
  uuid: string,
  formData: Pick<PaymentIntentAdminForm, 'amount' | 'reasonForChange' | 'source'>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<PaymentIntent>(
    `/zeus/payment-intent/${uuid}/manual_add_payment/`,
    formData,
  );
};

export const reopenStudentProfileForApplication = (studentProfileId: StudentProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>(
    `/zeus/reopen-student-profile-for-application/${studentProfileId}/`,
  );
};

export const reopenStudentApplication = (studentApplicationId: StudentApplicationId) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<EmptyResponse>(
    `/zeus/reopen-student-application/${studentApplicationId}/`,
  );
};

export const getMentorsMissingFields = (mentorProfileId: MentorProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<
    CamelCasedPropertiesDeep<{ profile_complete: boolean; missing_fields: string[] }>
  >(`/zeus/mentors/${mentorProfileId}/missing-fields/`);
};

export const handleFeedbackRequest = (
  feedbackRequestId: FeedbackRequestId,
  action: 'contact_reviewer' | 'confirm_reviewer' | 'send_feedback',
  payload: HandleFeedbackRequestPayload,
) => {
  const internalFetch = getRestClient();
  return internalFetch.post<CamelCasedPropertiesDeep<{ feedback_reviewer: FeedbackReviewer }>>(
    `/zeus/feedback-requests/${feedbackRequestId}/${action}/`,
    payload,
  );
};

export const updateFeatureRequest = (
  featureRequestId: FeedbackRequestId,
  payload: Partial<FeatureRequest>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<FeatureRequest>(
    `/zeus/feature-requests/${featureRequestId}/`,
    payload,
  );
};

export const createFeatureRequest = (payload: {
  title: string;
  description: string;
  submitted_by: UserId;
}) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean }>('/zeus/feature-requests/', payload);
};

export const publishCaseStudy = (caseStudyId: CaseStudyId) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<{ message: string }>(`/zeus/case-studies/${caseStudyId}/publish/`);
};

export const rejectCaseStudy = (caseStudyId: CaseStudyId) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<{ message: string }>(`/zeus/case-studies/${caseStudyId}/reject/`);
};

export const changeAdmissionAdvisor = (id: StudentProfileId, aaId: Nullable<UserId>) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<{ admission_advisor: Nullable<UserId> }>(
    `/zeus/student-applications/${id}/change-admission-advisor/`,
    {
      admissionAdvisor: aaId,
    },
  );
};

export const getConfigurations = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<Configuration[]>('/zeus/configurations/');
};

export const updateUserPermissions = (
  id: number,
  payload: { isIntern?: boolean; isActive?: boolean; isTest?: boolean },
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<UserPermissionUpdate>(`/zeus/users/${id}/permissions/`, payload);
};

export const getResearchHubs = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<ResearchHubList[]>('/zeus/research-hub/');
};

export const getSingleResearchHub = (slug: Slug) => {
  const internalFetch = getRestClient();
  return internalFetch.get<ResearchHub>(`/zeus/research-hub/${slug}/`);
};

export const updateSingleResearchHub = (slug: Slug, payload: Partial<ResearchHubUpdate>) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<ResearchHub>(`/zeus/research-hub/${slug}/`, payload);
};

export const getProjects = (searchObj: ProjectSearchParams) => {
  const internalFetch = getRestClient();
  return internalFetch.get<Paginated<ProjectOverviewList>>('/zeus/projects/', {
    params: searchObj,
  });
};

export const getAdminRoles = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<Role[]>('/zeus/admin-role/');
};

export const updateAdminRole = (roleId: RoleId, payload: { users: (UserId | string)[] }) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<SimpleRole>(`/zeus/admin-role/${roleId}/`, payload);
};

export const updateUserRoles = (userId: UserId, payload: { roles: (RoleId | string)[] }) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<{ id: UserId; roles: RoleId[]; rolesWithDetails: SimpleRole[] }>(
    `/zeus/users/${userId}/roles/`,
    payload,
  );
};

export const updatePremiumShowcasingMatchingRequest = (
  uuid: UUID,
  payload: Partial<PremiumShowcasingSupportMatchingUpdate>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<PremiumShowcasingSupportMatching>(
    `/zeus/premium-showcasing-support/${uuid}/`,
    payload,
  );
};

export const createPodsCourse = (data: PodsCourseCreate) => {
  const internalFetch = getRestClient();
  return internalFetch.post<PodsCourseCreate>('/zeus/pods/courses/', data);
};

export const getPodsCounts = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ cohorts: number; courses: number }>('/zeus/pods/counts/');
};

export const getPodCourseDetails = (courseId: CourseId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<PodsCourseDetails>(`/zeus/pods/courses/${courseId}/`);
};

export const getPodCohortDetails = (cohortId: CohortId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<PodsCohortDetails>(`/zeus/pods/cohorts/${cohortId}/`);
};

export const updatePaymentIntentProducts = (uuid: UUID, products: AdminPaymentIntentProducts) => {
  const internalFetch = getRestClient();

  return internalFetch.patch<AdminPaymentIntentProducts>(
    `/zeus/payment-intent/${uuid}/products/`,
    products,
  );
};

export const createPodsCohort = (payload: PodsCohortCreatePayload) => {
  const internalFetch = getRestClient();
  return internalFetch.post('/zeus/pods/cohorts/', payload);
};

export const updatePodCohortDetails = (cohortId: CohortId, data: PodsCohortCreatePayload) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<PodsCohortDetails>(`/zeus/pods/cohorts/${cohortId}/`, data);
};

export const changeEmailByOps = (userId: UserId, payload: { email: string }) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean }>(`/zeus/${userId}/change-email-by-ops/`, payload);
};

export const getTaskMonitoring = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<TaskMonitoring>('/zeus/task-monitoring/');
};
