import axios, { AxiosError } from 'axios';

import getResizedImageUrl from 'src/components/aux/getResizedImageUrl';
import defaultProfileImage from 'src/components/static/images/generic-profile.jpg';

type FieldErrors = Record<string, string>;

export function getFieldErrors(errorBody: Record<string, string>) {
  const fieldErrors = Object.entries(errorBody);
  return fieldErrors.reduce<FieldErrors>((acc, [fieldName, errorList]) => {
    if (fieldName === 'non_field_errors' || fieldName === 'error') {
      return { ...acc, generic_error_message: errorList };
    } else {
      return { ...acc, [`${fieldName}_error`]: errorList };
    }
  }, {});
}

function getErrors(error: Error | AxiosError) {
  const data = axios.isAxiosError(error)
    ? (error.response?.data as Record<string, string>) ?? {}
    : {};

  const errors: Record<string, string> = {};
  if (Object.keys(data).length) {
    Object.entries(data).forEach(([key, message]) => {
      const errorKey = ['message', 'nonFieldErrors'].includes(key) ? 'error' : key;
      const errorMessage = `${errorKey}: ${message}`;
      // eslint-disable-next-line fp/no-mutation
      errors[key] = errorMessage;
    });
  }
  // @ts-expect-error I have no idea how this .body is possible here
  if (!Object.keys(errors).length && error.body) {
    // @ts-expect-error I have no idea how this .body is possible here
    return { ...errors, ...getFieldErrors(error.body as Record<string, string>) };
  }
  return errors;
}

export function getSingleErrorMessage(error: Error | AxiosError): string {
  const errors = getErrors(error);
  return Object.values(errors)[0] ?? 'Something went wrong';
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(functionToCheck: unknown): functionToCheck is Function {
  if (functionToCheck) {
    return {}.toString.call(functionToCheck) === '[object Function]';
  }
  return false;
}

export function getCssVariable(variable: string) {
  return getComputedStyle(document.documentElement).getPropertyValue(variable)?.trim();
}

// not final implementation
export function getCssVariableWithoutUnit(variable: string) {
  return Number(getCssVariable(variable).replace('px', ''));
}

export function isArrayWithItems<T>(array: Array<T>) {
  return Array.isArray(array) && array.length > 0;
}

export function getProfilePicture(picture: string, size = '140') {
  return picture
    ? getResizedImageUrl(`https://dpl6hyzg28thp.cloudfront.net/media/${picture}`, size)
    : defaultProfileImage;
}

export function isBlank(value: string | null | undefined) {
  if (value == null || (typeof value === 'string' && value === '')) {
    return true;
  }
  return false;
}

export function countWords(words: string | null | undefined) {
  if (words == null) {
    return 0;
  }

  return words.length === 0
    ? 0
    : words.split(/\s+/).filter((e) => {
        return e !== '';
      }).length;
}

export function getStaticAsset(fileName: string) {
  const baseUrl = import.meta.env.BASE_URL === '/' ? '' : import.meta.env.BASE_URL;

  return `${baseUrl}/static/images/${fileName}`;
}

export function concatenateCommasAnd(fieldArray: Array<string>) {
  return [fieldArray.slice(0, -1).join(', '), fieldArray.slice(-1)[0]].join(
    fieldArray.length < 2 ? '' : ' and ',
  );
}

export function replaceUnderscore(fieldArray: Array<string>) {
  return fieldArray.map((field) => {
    return field.replace(/_/g, ' ');
  });
}

export function convertToKeyString(originalString: string) {
  return originalString ? originalString.replace(/\s/g, '_').toLowerCase() : '';
}

export function isPositiveNumber(value: number) {
  return Number.isFinite(value) && value > 0;
}
