import { ProjectType, type ProjectTypes } from '@polygence/common';
import * as Sentry from '@sentry/react';

const PROJECT_TYPE_LABELS: Record<ProjectTypes, string> = {
  [ProjectType.FLAGSHIP]: 'Core Program',
  [ProjectType.PATHFINDER_DEPRECATED]: 'Pathfinder (deprecated)',
  [ProjectType.PATHFINDER_DIAGNOSTIC]: 'Pathfinder Diagnostic Call',
  [ProjectType.PATHFINDER_LAUNCHPAD]: 'Launchpad',
  [ProjectType.PATHFINDER_STANDALONE]: 'Pathfinder',
  [ProjectType.PREMIUM_SHOWCASING_PUBLISHING]: 'Premium Showcasing\u00A0- Publishing',
  [ProjectType.PREMIUM_SHOWCASING_PRESENTING]: 'Premium Showcasing\u00A0- Presenting',
  [ProjectType.PREMIUM_SHOWCASING_COMPETING]: 'Premium Showcasing\u00A0- Competing',
  [ProjectType.PREMIUM_SHOWCASING_MULTIMEDIA]: 'Premium Showcasing\u00A0- Multimedia',
  [ProjectType.PODS]: 'Pods',
  [ProjectType.ADVANCED_SCHOLARS_PROGRAM]: 'Advanced Scholars Program',
};

export const getLabelForProjectType = (projectType: string): string => {
  if (!(projectType in PROJECT_TYPE_LABELS)) {
    Sentry.captureMessage(`No label available for project type: ${projectType}`);
    return projectType;
  }
  return PROJECT_TYPE_LABELS[projectType as ProjectTypes];
};
