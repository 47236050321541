import {
  object,
  optional,
  string,
  array,
  number,
  literal,
  Input,
  ObjectEntries,
  union,
  variant,
  isoTimestamp,
  nullable,
  coerce,
} from 'valibot';

const aiThreadMessage = <T extends ObjectEntries>(options: Parameters<typeof object<T>>[0]) =>
  object({ ...options, aiThreadId: number() });

const ToolCallsSchema = optional(
  nullable(
    array(
      object({
        type: literal('function'),
        id: string(),
        function: object({
          name: string(),
          arguments: string(),
        }),
      }),
    ),
  ),
);

const MessageChunkSchema = aiThreadMessage({
  command: literal('message_chunk'),
  content: string(),
  toolCalls: ToolCallsSchema,
});

export type MessageChunk = Input<typeof MessageChunkSchema>;
const MessageSchema = object({
  id: optional(number()),
  createdAt: optional(string([isoTimestamp()])),
  role: union([literal('user'), literal('assistant')]),
  content: string(),
  name: optional(string()),
  extras: optional(object({ userSnippets: optional(array(number())) })),
  toolCalls: ToolCallsSchema,
  clientId: optional(string()),
});
export type Message = Input<typeof MessageSchema>;

const UserMessageSchema = aiThreadMessage({
  command: literal('user_message'),
  message: MessageSchema,
});
export type UserMessage = Input<typeof UserMessageSchema>;

const MessageFinishSchema = aiThreadMessage({
  command: literal('message_finish'),
  message: MessageSchema,
});
export type MessageFinish = Input<typeof MessageFinishSchema>;

const ConversationTitleSchema = aiThreadMessage({
  command: literal('conversation_title'),
  content: string(),
});

const UpdateMessageSchema = aiThreadMessage({
  command: literal('update_message'),
  id: coerce(number(), Number),
  content: string(),
});

export const WebSocketMessageSchema = variant('command', [
  MessageChunkSchema,
  MessageFinishSchema,
  ConversationTitleSchema,
  UserMessageSchema,
  UpdateMessageSchema,
]);
export type WebSocketMessage = Input<typeof WebSocketMessageSchema>;
